/* globals JS_FILES: true, JS_LIBS: true, ANGULAR_MAIN_MODULE: true */

require([
	"//code.jquery.com/jquery-1.11.3.min.js"
	], function() {
	require([
		'//maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js',
		"//ajax.googleapis.com/ajax/libs/angularjs/1.5.5/angular.min.js"
	], function() {
		require(JS_LIBS, function(CloverShared) {
			window.CloverShared = CloverShared.CloverShared;
			require([ANGULAR_MAIN_MODULE], function() {
				require(JS_FILES, function() {
					angular.bootstrap(document, ['gyftApp']);
				});
			});
		});
	});
});
define("main", function(){});

